// if (window.location.pathname === "/") {
//   // スクロール時の処理を設定
//   window.addEventListener("scroll", function () {
//     let header = document.querySelector(".l-header");
//     let topHero = document.querySelector(".p-top-hero");

//     // #js-top-informationが表示されているかを確認
//     if (window.scrollY > topHero.offsetHeight / 3) {
//       // #js-top-informationに到達した場合、.l-headerにis-scrolledクラスを追加
//       header.classList.add("is-scrolled");
//       // 画像のsrcを切り替える
//       document.querySelector(".l-header-logo img").src = "/assets/img/common/logo.svg";
//     } else {
//       // #js-top-informationを過ぎた場合、.l-headerからis-scrolledクラスを削除
//       header.classList.remove("is-scrolled");
//       // 画像のsrcを元に戻す
//       document.querySelector(".l-header-logo img").src = "/assets/img/common/logo_white.svg";
//     }
//   });
// } else{
//   document.querySelector(".l-header-logo img").src = "/assets/img/common/logo.svg";
// }

// ページのURLを取得
let currentPageURL = window.location.pathname;

// ヘッダー内のリンク要素を取得
let headerLinks = document.querySelectorAll(".l-header-nav__global__list__item__link");

// 関数を定義して、URLの第一階層を取得する
function getFirstLevelURL(url) {
  let urlParts = url.split('/');
  if (urlParts.length > 1) {
    return '/' + urlParts[1] + '/';
  }
  return '/';
}

// リンク要素をループして条件に合致するものにクラスを付与
headerLinks.forEach(function(link) {
  let linkURL = link.getAttribute("href");
  if (currentPageURL === linkURL || getFirstLevelURL(currentPageURL) === getFirstLevelURL(linkURL)) {
    link.classList.add("is-here");
  }
});


// ページ内に.hamburger-buttonがあったら.c-pageTitleにis-hamburger-buttonクラスを付与
if (document.querySelector(".hamburger-button")) {
  document.querySelector(".c-pageTitle").classList.add("is-hamburger-button");
}