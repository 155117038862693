// ウィンドウがスクロールされたときのイベントリスナーを追加
window.addEventListener('scroll', function () {
    // .js-fadeの要素すべてに対して処理を行う
    var fadeElements = document.querySelectorAll('.js-fade');
    
    fadeElements.forEach(function (element) {
        // .js-fadeの要素が画面に入ったかどうかの条件
        var elementOffsetTop = element.getBoundingClientRect().top;
        var windowHeight = window.innerHeight;
        var isElementInView = elementOffsetTop < windowHeight && elementOffsetTop > -element.clientHeight;

        // 画面に入ったとき
        if (isElementInView) {
            // 画面から外れる130px前に.fade-inを削除
            if (elementOffsetTop < -windowHeight + 250 && elementOffsetTop > -windowHeight) {
                element.classList.remove('fade-in');
            } else {
                // 高さが100未満なら.fade-inを追加、そうでないなら削除
                if (elementOffsetTop <= 250) {
                    element.classList.add('fade-in');
                } else {
                    element.classList.remove('fade-in');
                }
            }
        }
    });
});
