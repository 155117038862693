// ビデオ要素を取得
let video = document.getElementById("js-mainVideo");

if (video !== null) {
  // 停止ボタンをクリックしたときの処理
  document
    .getElementById("js-videoPauseButton")
    .addEventListener("click", function () {
      if (video) {
        video.pause(); // ビデオを停止
        video.currentTime = 0; // ビデオを先頭に巻き戻す
      }
    });

  // ページの読み込みが完了したときの処理
  window.addEventListener("load", function () {
    if (video) {
      video.play(); // ビデオを再生
    }
  });
}
