import Swiper from "swiper";

// トップページのスライダー
window.addEventListener("DOMContentLoaded", () => {
  const swiper01 = new Swiper(".js-topHeroSlider", {
    // slidesPerView: "auto",
    slidesPerView: 1,
    // spaceBetween: 40,
    // centeredSlides: true,
    freeMode: false,
    loop: true,
    effect: 'fade',
    speed: 2500,
    autoplay: {
      delay: 5000,
    },
    // navigation: {
    //   nextEl: ".swiper-button-next",
    //   prevEl: ".swiper-button-prev",
    // },
    // breakpoints: {
    //   768: {
    //     slidesPerView: 1.5,
    //     spaceBetween: 16,
    //     centeredSlides: true,
    //   },
    // },
  });

  const swiper02 = new Swiper(".js-topServiceSlider", {
    // slidesPerView: "auto",
    slidesPerView: 3.5,
    spaceBetween: 40,
    // centeredSlides: true,
    freeMode: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: 1.5,
        spaceBetween: 16,
        centeredSlides: true,
      },
    },
  });
});
