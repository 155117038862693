// IDに依存する
// document.addEventListener('DOMContentLoaded', function() {
//   // c-pageNav__list__itemがクリックされたときの処理
//   let navItems = document.querySelectorAll('.c-pageNav__list__item');
//   navItems.forEach(function(item) {
//     item.addEventListener('click', function(event) {
//       event.preventDefault(); // デフォルトのクリックイベントを無効化

//       // すべてのc-pageNav__list__itemからis-activeクラスを削除
//       navItems.forEach(function(item) {
//         item.classList.remove('is-active');
//       });

//       // クリックされたc-pageNav__list__itemにis-activeクラスを付与
//       this.classList.add('is-active');

//       let targetSectionId = this.querySelector('span').getAttribute('href').substring(1);
//       showServiceDetail(targetSectionId);
//     });
//   });

//   // セクションの表示切り替え関数
//   function showServiceDetail(targetSectionId) {
//     let serviceDetailSections = document.querySelectorAll('.p-service-detail');
//     serviceDetailSections.forEach(function(section) {
//       if (targetSectionId === 'service_all') {
//         section.style.display = 'block'; // "all" ナビゲーションがクリックされた場合は全てのセクションを表示
//       } else {
//         section.style.display = section.id === targetSectionId ? 'block' : 'none';
//       }
//     });
//   }
// });


// IDに依存しない
document.addEventListener('DOMContentLoaded', function() {
  // c-pageNav__list__itemがクリックされたときの処理
  let navItems = document.querySelectorAll('.c-pageNav__list__item');
  navItems.forEach(function(item, index) {
    item.addEventListener('click', function(event) {
      event.preventDefault(); // デフォルトのクリックイベントを無効化

      // すべてのc-pageNav__list__itemからis-activeクラスを削除
      navItems.forEach(function(item) {
        item.classList.remove('is-active');
      });

      // クリックされたc-pageNav__list__itemにis-activeクラスを付与
      this.classList.add('is-active');

      // クリックされたc-pageNav__list__itemの位置に対応するセクションを表示
      showServiceDetail(index);
    });
  });

  // セクションの表示切り替え関数
  function showServiceDetail(index) {
    let serviceDetailSections = document.querySelectorAll('.p-service-detail');
    serviceDetailSections.forEach(function(section, i) {
      section.style.display = index === 0 || i === index - 1 ? 'block' : 'none';
    });
  }
});