import Vue from "vue/dist/vue.esm.js";

document.addEventListener("DOMContentLoaded", function() {
  // js-helpTabがある場合のみ実行
  if (document.getElementById("js-helpTab")) {
    new Vue({
      el: "#js-helpTab",
      data: {
        isActive: "1",
      },
      methods: {
        isSelect: function (num) {
          this.isActive = num;
        },
      },
    });
  }
});